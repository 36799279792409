'use strict';

Gri.module({
  name: 'carousel-alpha',
  ieVersion: null,
  $el: $('.carousel-alpha'),
  container: '.carousel-alpha',
  fn: function () {
    this.$el.owlCarousel({
      autoplay:true,
      autoplayTimeout:5500,
      loop:true,
      singleItem: true,
      items: 1,
      dots: true,
      slideSpeed: 300,
      animateOut: 'fadeOut'
    });

    var winHeight = $(window).height();
    var winWidth = $(window).width();
    var headerHeight = $(".header-alpha").height();

    /*
    var idealHeight = "100vh";
    $('.carousel-alpha').height(idealHeight);
    $('.carousel-alpha .item').height(idealHeight);
    $('.carousel-alpha .item .img').height(idealHeight);
    $('.carousel-alpha .wrapper').height(idealHeight);
    */
    function fullHeight(){
      winHeight = $(window).height();
      winWidth = $(window).width();
      headerHeight = $(".header-alpha").height();

      if(winWidth < 767){
        $('.carousel-alpha').height("460px");
        $('.carousel-alpha .item').height("460px");
        $('.carousel-alpha .item .img').height("460px");
        $('.carousel-alpha .wrapper').height("460px");
      } else {
        /*
        $('.carousel-alpha').height(idealHeight);
        $('.carousel-alpha .item').height(idealHeight);
        $('.carousel-alpha .item .img').height(idealHeight);
        $('.carousel-alpha .wrapper').height(idealHeight);
        */
      }
    }
    fullHeight();
    $(window).on("resize", function(){
      fullHeight();
    });

  }
});
